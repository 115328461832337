import { Link } from 'gatsby'
import React from 'react'
import LogoB from 'images/svgs/bantu-logo-b.svg'
import { motion } from 'framer-motion'
import 'styles/components/header.scss'

const Header = (props: HeaderProps) => (
  <header className="header-container">
    {/* This will be some kind of cool animate with the B logo   */}
    {/* <motion.div
            whileHover={{
                rotate: -720,
                scale: 2,
                translateX: -50,
                translateY: 50
            }}
            whileTap={{
                rotate: -720,
                scale: 2,
                translateX: -50,
                translateY: 50
            }}
            animate={{
                rotate: [-180, -360],
                scale: [2, 1]
            }}
            transition={{
                type: "spring",
                stiffness: 250,
                duration: 0.5,
                damping: 20
            }}
            className="logo--b-container"
        >
            <LogoB />
        </motion.div> */}
    <Link className="logo-container" to="/">
      <LogoB className="logo" />
    </Link>
    <nav>
      <ul className="menu-links">
        <li className="margin-x-4">
          <Link to="/bantu-directory">Bantu Directory</Link>
        </li>
        <li className="margin-x-4">
          <Link to="/about">About Bantu</Link>
        </li>
        <li>
          <Link to="/meet-the-crew">Meet the Crew</Link>
        </li>
      </ul>
    </nav>
  </header>
)

interface HeaderProps {
  siteTitle?: string
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
